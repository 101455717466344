import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import kebabCaseIcon from 'utils/kebab-case-icon';
import Icon from 'components/icon';
import Clickable from 'components/clickable';
import Markdown from 'components/markdown';

import ProxyLogoText from 'assets/svg/proxy-logo-text.svg';

import s from './Footer.scss';

const date = new Date();

const Footer = ({ links, rightHeading, rightText, subLinks, socialLinks }) => (
  <div className={s.footer}>
    {/* Logo */}
    <div className={s.footer__segment}>
      <div className={s.footer__container}>
        <ProxyLogoText className={s.footer__logo} />
      </div>
    </div>
    <div className={s.footer__segment}>
      <div className={s.footer__container}>
        <div className={s.footer__nav}>
          {/* Navigation */}
          {links && links.map(column => (
            <div className={s.footer__column} key={column.heading}>
              <p className={s.footer__heading}>{column.heading}</p>
              {column.links &&
                <ul className={s.footer__list}>
                  {column.links.map(link => (
                    <li key={link.heading} className={s.footer__item}>
                      <Clickable className={s.footer__link} to={link.url} navLink>
                        {link.heading}
                      </Clickable>
                    </li>
                  ))}
                </ul>
              }
            </div>
          ))}
          {/* Contact info */}
          <div className={s.footer__connect}>
            <div className={s.footer__connectVr} />
            <div className={s.footer__connectContent}>
              <p className={s.footer__heading}>{rightHeading}</p>
              <Markdown source={rightText} className={s.footer__markdown} />
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Social + copyright */}
    <div className={s.footer__segment}>
      <div className={s.footer__container}>
        <div className={s.footer__sub}>
          <div className={s.copyright}>
            <span className={s.copyright__text}>
              Copyright {date.getFullYear()} All Rights Reserved
            </span>
            {subLinks.map(link => (
              <span key={link.url} className={s.copyright__text}>
                <Clickable className={s.footer__subLink} to={link.url} navLink>
                  {link.heading}
                </Clickable>
              </span>
            ))}
          </div>
        </div>
        <div className={s.footer__sub}>
          <a href={'https://beian.miit.gov.cn/'}>沪ICP备20019296号-2</a>
        </div>
      </div>
    </div>
  </div>
);

const query = graphql`
  query Footer {
    nav: contentfulNavigation {
      links: footerLinks {
        ...navGroup
      }
      rightHeading: footerRightTitle
      rightText: footerRightBody {
        text: footerRightBody
      }
      subLinks: footerSubLinks {
        ...navLink
      }
      socialLinks {
        ...navLink
      }
    }
  }
`;

export default props => (
  <StaticQuery
    query={query}
    render={data => (
      <Footer
        links={data.nav.links}
        rightHeading={data.nav.rightHeading}
        rightText={data.nav.rightText.text}
        subLinks={data.nav.subLinks}
        socialLinks={data.nav.socialLinks}
        {...props}
      />
    )}
  />
);

Footer.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          heading: PropTypes.string.isRequired,
          icon: PropTypes.string,
          url: PropTypes.string,
          textShort: PropTypes.string,
          textLong: PropTypes.string,
        }),
      ),
      lists: PropTypes.arrayOf(
        PropTypes.shape({
          heading: PropTypes.string,
          url: PropTypes.string,
        }),
      ),
    }),
  ),
  rightHeading: PropTypes.string,
  rightText: PropTypes.string,
  subLinks: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
};
